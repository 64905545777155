body {
  background-color: #f3f3f3;
  /* transition: background-color 1s; */
}

div.App {
  height: 100%;
  width: 100%;
}

.highlight-container > * {
  overflow: hidden !important;
  resize: none !important;
}

/* typeform like style, to revert get rid of border-specific styling and change color back */
input.text {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;

  border-radius: 0px;
  border-width: 2px;
  border-color: #6290dd;
  border-style: solid;
  margin-bottom: 1.5rem !important;
  display: block;
  box-shadow: none !important;
  /* border-radius: 5px; */
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  outline: none;
}

textarea {
  resize: none;
  overflow: hidden !important;
  min-width: stretch;
  margin-bottom: 1.5rem !important;
  border-radius: 0px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-width: 2px !important;
  border-color: #6290dd !important;
  border-top-style: hidden !important;
  border-left-style: hidden !important;
  border-right-style: hidden !important;
  outline: none !important;
}

div.form-step {
  margin: 5vh 5vw 0 5vw;
  padding: 50px 50px 50px 50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: stretch !important;
}

h1 {
  font-size: calc(10px + 10vmin) !important;
}

p {
  font-family: Comfortaa;
}

b {
  color: #6290dd;
}

div.footer {
  /* position: absolute; */
  width: fill;
  bottom: 0;
  align-self: flex-end;
  margin-top: 3rem;
}

p.footer {
  font-size: 10px;
  text-align: center;
  margin-bottom: 0;
}

p.explanation {
  font-size: 15px;
  font-style: italic;
  color: #726b76;
}

p.success {
  font-size: 12px;
  font-style: italic;
  color: #6290dd;
}

textarea.final {
  white-space: pre-line;
  font-style: italic;
  background-color: #f3f3f3;
  padding: 1rem 1rem 1rem 1rem;
  border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  min-height: 200px;
}

div.reset-buttons {
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

button.reset-button {
  font-size: 0.75rem !important;
  width: 50%;
  margin: 0px 1px 0px 1px;
}

div.step-buttons {
  display: flex;
  justify-content: flex-end;
}

button.step-button {
  font-size: 0.5rem !important;
  width: 30px;
  height: 30px;
  margin: 0px 1px 0px 1px;
}

div.step-buttons-container {
  margin-top: 1.5rem;
}

button {
  /* height: 1.75rem; */
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border-width: 0px !important;
  border-color: #6290dd;
  background-color: #6290dd;
  font-family: "Comfortaa" !important;
  font-size: 15px !important;
  width: 50%;
  cursor: pointer;
}

button.return {
  font-size: 0.75rem !important;
  width: 30%;
}

button.center {
  align-self: center;
  font-size: 0.75rem !important;
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
button.start {
  align-self: center;
  font-size: 0.75rem !important;
  width: 75%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

button > a {
  text-decoration: none;
  color: white;
}

p > a {
  text-decoration: none;
  border-bottom: 2px solid #6290dd;
  color: inherit;
}

button.link-button {
  align-self: center;
  font-size: 0.75rem !important;
  width: 50%;
  opacity: 0.75;
  margin-top: 1rem;
}

i.far.fa-copy {
  margin-right: 0.5rem;
}

i.fas.fa-plus,
i.far.fa-edit {
  margin-right: 0.5rem;
}

i.fas.fa-check {
  margin-right: 0.5rem;
}
i.fas.fa-link {
  margin-right: 0.5rem;
}

p.icon {
  font-size: 0.5rem !important;
  margin-left: 0.25rem;
  display: inline;
}

i.fas.fa-level-down-alt {
  color: #6290dd;
  position: relative;
  top: 2px;
  margin-left: 0.5rem;
  transform: rotate(90deg);
}

div.final {
  margin-top: 2rem;
}

mark {
  background-color: #f0c808 !important;
  border-radius: 5px;
}

div.highlight-container {
  height: 100%;
}

/* css from react-highlight-within-textarea */
/* .container {
  display: inline-block;
  position: relative;
  overflow: hidden !important;
  -webkit-text-size-adjust: none !important;
  border-width: 2px;
}

.backdrop {
  position: absolute !important;
  top: 0 !important;
  right: -99px !important;
  bottom: 0 !important;
  left: 0 !important;
  padding-right: 99px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  padding: 25px 25px 25px 25px !important;
}

.highlights {
  width: auto !important;
  height: auto !important;
  border-color: transparent !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  color: transparent !important;
  overflow: hidden !important;
}

.input {
  display: block !important;
  position: relative !important;
  margin: 0;
  padding: 0;
  border-radius: 0;
  font: inherit;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.content {
  border: 1px solid;
  background: none transparent !important;
}

.content mark {
  padding: 0 !important;
  color: inherit;
} */
